
  .videoContainer{
      height:100%;
      display: flex;
      flex-direction: row;
      gap:20px;
      /* position: relative; */
  }
  .pubisherVideo{
      background:blue;
      position: relative;
  }
  .subscriberVideo{
    background:green;
    position: relative;
  }

  .OT_publisher, .OT_subscriber{
    position: absolute !important;
}

  @media (min-width: 1024px) {
    .waiting_container{
      height:95vh;
      margin:20px;
  }
    .pubisherVideo{
      flex: 1;
    }
    .subscriberVideo{
      flex: 1;
    }
  }
  @media (max-width: 1023px) {
    .videoContainer {
      flex-direction: column;
    }
}

  @media (max-width: 912px){
    .waiting_container{
      height:100vh;
      max-width:100vw;
      padding:0px;
  }

    .pubisherVideo{
        width:150px;
        height:150px;
        position:absolute;
        bottom:0;
        right:0;
        z-index: 100;
    }
    .subscriberVideo{
      height:100%;
    }
}